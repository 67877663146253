import { ProductImageCarousel } from './carousel/productImageCarousel';
import { ProductThumbnailsCarousel } from './carousel/productThumbnailsCarousel';
import { initClick2BuySizes } from './products/click2buySizes';

window.HMSProductDetail = class HMSProductDetail {
  constructor() {
    this.init();
    this.step = 0;
  }

  init() {
    if (ProductImageCarousel.isOnPage()) {
      this.ProductImageCarousel = new ProductImageCarousel();
    }

    if (ProductThumbnailsCarousel.isOnPage()) {
      this.ProductThumbnailsCarousel = new ProductThumbnailsCarousel();
		}

		initClick2BuySizes();
  }
}
