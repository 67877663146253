/**
 * Changes data-widget-id attribute when the size changes
 * @param {Event} event - change event of the <select>
 */
function changeWidgetIdOnSizeChange(event) {
	$(event.currentTarget).parents('.js-click-to-buy-container').find('.js-click-to-buy')
    .attr('data-widget-id', $(event.currentTarget).val());

	if (window.c2bWidget) {
		window.c2bWidget.init();
	}
}

/**
 * Used on click2 when multiple product sizes are available.
 */
export function initClick2BuySizes() {
	$('.js-click-to-buy-options').on('change', event => changeWidgetIdOnSizeChange(event));
}