import { Carousel } from './carousel';

const selector = '.js-product-thumbnails-carousel';
const selectorFrost = '.js-product-thumbnails-carousel--isFrost';

export class ProductThumbnailsCarousel extends Carousel {
  static isOnPage() {
    // return the frost selector if it exists otherwise default to the standard selector
    return document.querySelector(selectorFrost) || document.querySelector(selector);
  }

  constructor() {
    super();
    // eslint-disable-next-line new-parens
    this.carousel = new Map;

    // assign selector based on which one is present
    this.activeSelector = ProductThumbnailsCarousel.isOnPage() === document.querySelector(selectorFrost) ? selectorFrost : selector;
    super.init(this, this.activeSelector);

    this.carouselAPI = this.carousel.get('product-thumbnails-carousel-0');
    this.addEvents();
  }

  addEvents() {
    $('body').on('click', '.js-product-thumbnail', event => this
      .onThumbClick($(event.currentTarget).index()));
  }

  highlightActive() {
    const $items = $('.js-product-thumbnail');
    $items.removeClass('active');
    $items.eq(window.HMS.ProductDetail.step).addClass('active');
  }

  onThumbClick(index) {
    this.highlightActive(index);
    window.HMS.ProductDetail.ProductImageCarousel.navigateToSlide(index);
  }

  getActiveSlide() {
    return this.carouselAPI.activeIndex;
  }

  /**
   * Generate config
   * @returns {Object} config
   */
  generateConfig() {
    const isFrost = this.activeSelector === selectorFrost;

    return super.extendConfig({
      autoplay: false,
      pagination: false,
      loop: false,
      navigation: {
        nextEl: '.icon-nav-arrow-bottom',
        prevEl: '.icon-nav-arrow-top'
      },
      direction: 'vertical',
      slidesPerView: isFrost ? 7 : 4,
      slidesPerGroup: isFrost ? 7 : 4,
      spaceBetween: 5,
      speed: 1000,
      watchSlidesVisibility: true,
      autoHeight: false,
      lazy: true
    });
  }
}