import { Carousel } from './carousel';

const selector = '.js-product-image-carousel';

export class ProductImageCarousel extends Carousel {
  static isOnPage() {
    return document.querySelector(selector);
  }

  constructor() {
    super();

    this.carousel = new Map();
    super.init(this, selector);
    this.carouselAPI = this.carousel.get('product-image-carousel-0');
    this.addEvents();
    this.activateImageViewer();
  }

  addEvents() {
    this.carouselAPI.on('slideChange', () => {
      window.HMS.ProductDetail.step = this.carouselAPI.realIndex;
      window.HMS.ProductDetail.ProductThumbnailsCarousel.highlightActive();
    });
  }

  navigateToSlide(index) {
    this.carouselAPI.slideToLoop(index);
  }

  /**
   * Generate config
   * @returns {Object} config
   */
  generateConfig() {
    const config = {
      autoplay: false,
      pagination: false,
      loop: window.innerWidth > 768,
      navigation: {
        nextEl: '.carousel-nav-button-next',
        prevEl: '.carousel-nav-button-prev'
      },
      preloadImages: false,
      slideActiveClass: 'active',
      speed: 500,
      lazy: {
        loadOnTransitionStart: true
      }
    };
    if (document.querySelector('.js-product-carousel-frost')) {
      config.breakpoints = {
        768: {
          scrollbar: {
            hide: true,
          }
        },
      };
      config.scrollbar = {
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true
      };
    }

    return super.extendConfig(config);
  }

  activateImageViewer() {
    const api = this.carouselAPI;
    let media = [];
    let currentScrollTop;

    const setActiveItem = () => {
      const activeItemIndex = $.magnificPopup.instance.index;
      const activeItem = $(`.js-product-intro-carousel-thumbnail-img[data-index="${activeItemIndex}"]`);

      $('.js-product-intro-carousel-thumbnail-img').removeClass('product-intro-carousel-thumbnail-img--active');
      activeItem.addClass('product-intro-carousel-thumbnail-img--active');
    }

    $('.gallery-popup a').magnificPopup({
      type: 'image',
      mainClass: 'image-carousel-popup',
      zoom: {
        enabled: true,
        duration: 300
      },
      gallery: {
        enabled: true,
        tCounter: '%curr% / %total%'
      },
      iframe: {
        patterns: {
          azure: {
            index: 'azure.com',
            id: function () {
              return 0;
            },
            src: 'http://www.azure.com/embed/%id%'
          }
        }
      },
      callbacks: {
        beforeOpen: function() {
          // Remove swiper duplicate items and set real index
          const newItems = this.items.filter(item => {
              return !$(item).parent().hasClass('swiper-slide-duplicate');
          });
          this.items = newItems;
          this.index = window.HMS.ProductDetail.step;

          $('.images.gallery-popup .image:not(.swiper-slide-duplicate)').each(function() {
            const itemIndex = $(this).data('swiper-slide-index');
            const $image = $(this).find('img.product-intro-carousel-image');
            const $video = $(this).find('a.videoBackground');

            if ($video.length) {
              const existingVideoIndex = media.findIndex(item => item.itemIndex === itemIndex);

              if (existingVideoIndex === -1) {
                media.push({ type: 'video', src: $video.find('img').attr('data-src') || $video.find('img').attr('src'), itemIndex });
              }
            } else if ($image.length) {
              const existingImageIndex = media.findIndex(item => item.itemIndex === itemIndex);

              if (existingImageIndex === -1) {
                const src = $image.data('src') || $image.attr('src');
                media.push({ type: 'image', src, itemIndex });
              }
            }
          });
        },

        open: function() {
          const thumbnails = $('<section>').addClass('product-intro-carousel-thumbnail');

          media.forEach((item, index) => {
            let $element;

            if (item.type === 'video') {
              const elementContent = `
                <div class="product-intro-carousel-thumbnail-box">
                  <img data-index="${index}" src="${item.src}" class="product-intro-carousel-thumbnail-img js-product-intro-carousel-thumbnail-img" />
                  <svg x="0px" y="0px" viewBox="0 0 82 82" "
                  class="svg-icon play-icon">
                    <g>
                      <path class="play-icon-pause" d="M36,55.5c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2v-29c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2V55.5z"/>
                      <path class="play-icon-pause" d="M52,55.5c0,1.1-0.9,2-2,2h-2c-1.1,0-2-0.9-2-2v-29c0-1.1,0.9-2,2-2h2c1.1,0,2,0.9,2,2V55.5z"/>
                    </g>
                    <g>
                      <circle class="play-icon-circle" cx="41" cy="41" r="40"/>
                      <polygon class="play-icon-arrow" points="31.5,57.5 31.5,24.5 56.5,41"/>
                    </g>
                  </svg>
                </div>
              `;

              $element = $(elementContent);
            } else {
               $element = $('<img>', {
                'data-index': index,
                class: 'product-intro-carousel-thumbnail-img js-product-intro-carousel-thumbnail-img',
                src: item.src,
              });
            }

            $element.on('click', (event) => {
              event.stopPropagation();
              $('.gallery-popup').magnificPopup('goTo', index);
            });

            thumbnails.append($element);
          });

          $('.mfp-container').append(thumbnails);
          setActiveItem();

          // The following line gets the current vertical scroll position of the page
          currentScrollTop = $(window).scrollTop();
        },

        close: function() {
          media = [];
        },

        afterClose: function () {
          // This line resets the vertical scroll position of the page to what it was before the popup was opened.
          // This ensures that when the popup is closed, the user returns to the same spot on the page where they were before.
          $(window).scrollTop(currentScrollTop);
        },

        elementParse: function (item) {
          if (item.el[0].classList.contains('js-video-popup')) {
            item.type = 'jwplayer';
          } else {
            item.type = 'image';
          }
        },
        change: function () {
          setActiveItem();
          api.slideToLoop($.magnificPopup.instance.index);
          window.HMS.ProductDetail.ProductThumbnailsCarousel
            .highlightActive($.magnificPopup.instance.index - 1);
        }
      }
    });
  }
}
